import React from 'react'
import Article from '../Global/Article'
import { Row, Col } from '../../layouts/grid.css'
import Button from '../Global/Button'
import BtnIcon from '../Global/Icon/BtnIcon'
import ArrowRight from '../Global/Icon/ArrowRight'
import Newsletter from '../Global/Newsletter'
import * as S from './Blog.css'

const ArticleList = ({ articles, viewMore, homePage, showNewsletterForm }) => {
  return (
    <>
      <Row gutter={10}>
        {articles.map((item, index) => {
          const slug = item.slug || item.node.frontmatter.slug
          const data = item.node ? item.node.frontmatter : item
          return (
            <React.Fragment key={index}>
              <Col md={4}>
                <Article
                  style={{
                    marginTop:
                      (index === 1 || index === 4) && homePage ? '65px' : 0,
                  }}
                  slug={slug}
                  {...data}
                  homePage={homePage}
                />
              </Col>
              {index === 5 && showNewsletterForm && (
                <Col md={12}>
                  <Newsletter />
                </Col>
              )}
            </React.Fragment>
          )
        })}
      </Row>
      {viewMore && (
        <S.ViewMore>
          <Button type="link" transparent href="/thoughts/">
            More blogs &nbsp;
            <BtnIcon>
              <ArrowRight />
            </BtnIcon>
          </Button>
        </S.ViewMore>
      )}
    </>
  )
}

ArticleList.defaultProps = {
  featuredList: false,
  viewMore: false,
  homePage: false,
  showNewsletterForm: false,
}

export default ArticleList
