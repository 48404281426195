import React from 'react'
import { graphql } from 'gatsby'
import htmr from 'htmr'
import { Layout, Container } from '../layouts'
import { Heading } from '../components/paragraph/paragraph.css'
import Hero from '../components/Global/Hero'
import ArticleList from '../components/Blog/ArticleList'

const StaffPost = ({ data: { page, posts } }) => {
  const crumbs = [{ link: '/company/', label: 'Company' }]
  return (
    <Layout>
      <Hero title={page.frontmatter.name} crumbs={crumbs} small />
      <Container>{page.html && htmr(page.html)}</Container>
      {posts.edges && posts.edges.length > 0 && (
        <Container>
          <Heading>
            {page.frontmatter.short_name || page.frontmatter.name + `'s`} latest
            thoughts:
          </Heading>
          <ArticleList articles={posts.edges} />
        </Container>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        slug
        name
        position
        thumbnail {
          size
          absolutePath
          childImageSharp {
            fluid {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
        contact_details {
          email
          phone
        }
        socials {
          twitter
          linkedin
        }
      }
    }
    posts: allMarkdownRemark(
      filter: { frontmatter: { staff_profile: { eq: $slug } } }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            date(formatString: "MMMM Do, YYYY")
            tags
            author
          }
        }
      }
    }
  }
`

export default StaffPost
