import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { colors, fonts, buttons, media } from '../../../lib/constants'

export const Paragraph = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto 5rem;
  ${({ last }) => last && 'margin-bottom: 0;'}

  ul,
  ol,
  dt,
  dd,
  p,
  a {
    font-size: 1.375rem;
    line-height: 2.25rem;
  }

  ul,
  ol {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;

    li::marker {
      font-size: 0.875rem;
      position: relative;
      left: 5px;
    }
  }

  ${media.tablet`
    flex-direction: column;

    ul,
    ol,
    dt,
    dd,
    p,
    a {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  `}
`
export const Heading = styled.h3`
  color: ${colors.blue};
  font-family: ${fonts.headings};
  font-weight: normal;
  margin: 2rem 2rem 2rem 0;
  font-size: 1.75rem;
  line-height: 2.25rem;
  width: 25%;

  &:first-of-type {
    margin-top: 0;
    margin-right: 2rem;
  }

  ${props =>
    props.center &&
    css`
      text-align: center;
    `}

  ${media.tablet`
    width: 100%;
    margin-right: 0;
  `}
`

export const SubHeading = styled.small`
  display: block;
  font-family: ${fonts.body};
  font-weight: normal;
  font-style: italic;
  color: ${colors.grey};
  margin-top: 0.375rem;
  margin-bottom: 0;
  font-size: 1rem;
`

export const P = styled.div`
  width: 75%;
  font-size: 1.375rem;
  line-height: 2.25rem;

  ul,
  ol,
  dt,
  dd,
  p,
  a {
    font-size: 1.375rem;
    line-height: 2.25rem;
  }

  ul,
  ol {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;

    li::marker {
      font-size: 0.75rem;
      position: relative;
      left: 5px;
    }
  }

  ${media.tablet`
    flex-direction: column;
    width: 100%;

    ul,
    ol,
    dt,
    dd,
    p,
    a {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;

      ${Heading} {
        width: 100%;
      }
    `}
`

export const Button = styled.button`
  ${buttons.pinkhollow}
  display: inline-block;
  margin-top: 2.5rem;
  background-color: transparent;
  cursor: pointer;

  &::before {
    display: none;
  }
`

export const A = styled(Link)`
  ${buttons.pinkhollow}
  display: inline-block;
  margin-top: 2.5rem;

  &::before {
    display: none;
  }
`
